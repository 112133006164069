<template>
  <CommonBottomDrawer
    v-on="hasCloseEventListener ? { close: handleClose } : {}"
  >
    <template #header>Your browser is not supported</template>
    <template #content>
      <div class="UnsupportedBrowserModal__wrapper">
        <p class="UnsupportedBrowserModal__text">
          Some features may not work as expected because you're using an
          outdated or unsupported browser. For the best experience, please
          switch to one of the following browsers:
        </p>
        <ul class="UnsupportedBrowserModal__browsers">
          <li
            v-for="browser in browsers"
            :key="browser.url"
            class="UnsupportedBrowserModal__item"
          >
            <a
              :href="browser.url"
              target="_blank"
              class="UnsupportedBrowserModal__link"
              @click="analytics.trackOtherEvent(`download-${browser.id}-click`)"
            >
              <img
                class="UnsupportedBrowserModal__logo"
                :src="browser.logoSrc"
                loading="lazy"
              />
              {{ browser.name }}
              <o-icon
                class="UnsupportedBrowserModal__arrow"
                icon="arrow-right"
                custom-size="mdi-18px"
              />
            </a>
          </li>
        </ul>
      </div>
    </template>
  </CommonBottomDrawer>
</template>
<script setup lang="ts">
import { computed, getCurrentInstance } from "vue";
const emit = defineEmits<{ (e: "close"): void }>();

const device = useDevice();
const analytics = useAnalytics();

const hasCloseEventListener = computed(() => {
  return !!getCurrentInstance()?.vnode.props?.onClose;
});

const handleClose = () => {
  emit("close");
};

const desktopBrowsers = {
  chrome: {
    id: "chrome",
    name: "Download Google Chrome",
    url: "https://www.google.com/chrome/",
    logoSrc: "/img/browsers/chrome_logo.png",
  },
  edge: {
    id: "edge",
    name: "Download Edge",
    url: "https://www.microsoft.com/en-gb/edge/download",
    logoSrc: "/img/browsers/edge_logo.png",
  },
  firefox: {
    id: "firefox",
    name: "Download Firefox",
    url: "https://www.mozilla.org/en-GB/firefox/new/",
    logoSrc: "/img/browsers/firefox_logo.png",
  },
  opera: {
    id: "opera",
    name: "Download Opera",
    url: "https://www.opera.com/download",
    logoSrc: "/img/browsers/opera_logo.png",
  },
  safari: {
    id: "safari",
    name: "Open in Safari (iOS)",
    url: "x-safari-https://kingsconference.app",
    logoSrc: "/img/browsers/safari_logo.png",
  },
};

const mobileBrowsers = {
  chrome: {
    id: "chrome-mobile",
    name: "Download Google Chrome",
    url: "https://play.google.com/store/apps/details?id=com.android.chrome",
    logoSrc: "/img/browsers/chrome_logo.png",
  },
  safari: {
    id: "safari-mobile",
    name: "Open in Safari (iOS)",
    url: "x-safari-https://kingsconference.app",
    logoSrc: "/img/browsers/chrome_logo.png",
  },
};

const browsers = computed(() => {
  if (device.isDesktop) {
    const browsersArr = [
      desktopBrowsers.chrome,
      desktopBrowsers.edge,
      desktopBrowsers.firefox,
      desktopBrowsers.opera,
    ];
    if (device.isMacOS) {
      return [...browsersArr, desktopBrowsers.safari];
    }
    return browsersArr;
  }
  if (device.isMobileOrTablet) {
    const browsersArr = [mobileBrowsers.chrome];
    if (device.isIos) {
      return [...browsersArr, mobileBrowsers.safari];
    }
    return browsersArr;
  }
});
</script>
<style scoped lang="scss">
.UnsupportedBrowserModal {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    margin-bottom: 16px;
  }
  &__text {
    @include BodyMedium;
    color: $color-element-dark-medium;
  }
  &__browsers {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    row-gap: 54px;
  }
  &__item {
    position: relative;
    &:nth-child(2n + 1) {
      &::after,
      &::before {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: $color-neutral-100;
      }
    }
    &::after {
      top: -27px;
    }
    &::before {
      bottom: -27px;
    }
    &:first-child {
      &::after {
        content: unset;
      }
    }
    &:last-child {
      &::before {
        content: unset;
      }
    }
  }
  &__logo {
    width: 32px;
    height: 32px;
  }
  &__link {
    @include BodyLarge;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-element-dark-strong;
    text-decoration: none;
    column-gap: 8px;
  }
  &__arrow {
    color: $color-element-dark-weak;
    margin-left: auto;
  }
}
</style>
